import React, { useRef } from 'react';
import {
  StyledSideMenuDiv,
  StyledCloseIcon,
  ItemContainerDiv,
  StyledSideUL,
  StyledLoginA,
} from './SideMenu.styled';
import { HeaderMenuItem, HeaderItem, isHeaderItem } from '../Header';
import { SideMenuLink } from './SideMenuLink';
import { SideMenuList } from './SideMenuList';
import profileIcon from '../../../images/profileIcon.svg';
import { useClickOutside } from '../utils/useClickOutside';
import closeIcon from '../../../images/closeIcon.svg';

type menuProps = {
  isOpen: boolean;
  flipSideMenu: (status: boolean) => void;
  items: Array<HeaderItem | HeaderMenuItem>;
};

export const SideMenu = (props: menuProps) => {
  const { isOpen, flipSideMenu, items } = props;
  const menuRef = useRef<HTMLDivElement>(null);
  const clickHandler = () => {
    flipSideMenu(false);
  };
  useClickOutside(menuRef, clickHandler);

  return (
    <StyledSideMenuDiv isOpen={isOpen} ref={menuRef}>
      <StyledCloseIcon onClick={() => flipSideMenu(false)}>
        <img src={closeIcon} />
      </StyledCloseIcon>
      <ItemContainerDiv>
        <StyledLoginA href="https://my.ovoenergy.com/login">
          <div>
            <img src={profileIcon} />
          </div>
          Login to My Account
        </StyledLoginA>
      </ItemContainerDiv>
      <StyledSideUL>
        {items.map(item => {
          if (isHeaderItem(item)) {
            return (
              <SideMenuLink
                key={item.text}
                text={item.text}
                url={item.url}
              ></SideMenuLink>
            );
          } else {
            return (
              <SideMenuList
                key={item.text}
                text={item.text}
                items={item.items}
              />
            );
          }
        })}
      </StyledSideUL>
    </StyledSideMenuDiv>
  );
};
