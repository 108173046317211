import React from 'react';
import { StyledSideA, StyledLi } from './SideMenu.styled';

type menuProps = {
  text: string;
  url: string;
};

export const SideMenuLink = (props: menuProps) => {
  const { url, text } = props;
  return (
    <StyledLi>
      <StyledSideA href={url}>{text}</StyledSideA>
    </StyledLi>
  );
};
