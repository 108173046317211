import React from 'react';
import { StyledLI, StyledA, FlexRowDiv } from './Header.styled';

type HeaderLinkProps = {
  text: string;
  url: string;
};

export const HeaderLink = (props: HeaderLinkProps) => {
  const { text, url } = props;
  return (
    <StyledLI key={text}>
      <FlexRowDiv>
        <StyledA href={url}>{text}</StyledA>
      </FlexRowDiv>
    </StyledLI>
  );
};
