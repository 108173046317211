import React from 'react';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { StyledAppContainer, StyledIcons } from './Layout.styled';
import { MiniRegisterInterest } from './MiniRegisterInterest/MiniRegisterInterest';
import { MiniTerms } from './MiniTerm/MiniTerms';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/600.css';
import { LicenseDisclaimer } from './FakeDoor/LicenseDisclaimer';

interface Props {
  children: React.ReactNode;
  partnership: 'mini' | 'vanarama' | 'mitsubishi';
}

const headerItems = [
  { text: 'Get a quote', url: 'https://switch.ovoenergy.com/' },
  {
    text: 'Products',
    items: [
      {
        text: 'Home Energy Plans',
        url: 'https://www.ovoenergy.com/home-energy-plans',
      },
      { text: 'Smart Meters', url: 'https://www.ovoenergy.com/smart-meters' },
      { text: 'Electric Cars', url: 'https://www.ovoenergy.com/electric-cars' },
      { text: 'OVO Beyond', url: 'https://www.ovoenergy.com/ovo-beyond' },
      {
        text: 'Boiler and Home Emergency Cover',
        url: 'https://www.ovoenergy.com/ovo-home-plan',
      },
      {
        text: 'New Boilers',
        url: 'https://www.ovoenergy.com/boiler-replacement',
      },
      { text: 'Smart Home', url: 'https://www.ovoenergy.com/smart-home' },
      {
        text: 'Smart Thermostats',
        url: 'https://www.ovoenergy.com/smart-home/smart-thermostat',
      },
      {
        text: 'OVO Greenlight',
        url: 'https://www.ovoenergy.com/smart-home/ovo-greenlight?utm_medium=organicsocial&utm_source=native&utm_campaign=ovogreenlight&utm_term=productsmenu',
      },
      {
        text: 'Smart export guarantee (SEG)',
        url: 'https://www.ovoenergy.com/guides/energy-guides/smart-export-guarantee',
      },
    ],
  },
  { text: 'Moving home', url: 'https://www.ovoenergy.com/moving-home' },
  {
    text: 'About OVO',
    items: [
      { text: 'Our Story', url: 'https://www.ovoenergy.com/about' },
      {
        text: 'Our green energy',
        url: 'https://www.ovoenergy.com/green-energy',
      },
      { text: 'OVO Sustainability', url: 'https://www.ovo.com/planzero' },
      { text: 'OVO Foundation', url: 'https://www.ovofoundation.org.uk/' },
      { text: 'Careers', url: 'https://www.ovoenergy.com/careers' },
    ],
  },
  {
    text: 'Help',
    items: [
      {
        text: 'Price cap changes',
        url: 'https://www.ovoenergy.com/pricecap',
      },
      {
        text: 'Coronavirus Update',
        url: 'https://www.ovoenergy.com/coronavirus-update',
      },
      { text: 'Support and FAQs', url: 'https://www.ovoenergy.com/help' },
      {
        text: 'Energy guides',
        url: 'https://www.ovoenergy.com/guides/energy-guides',
      },
      { text: 'OVO Forum Community', url: 'https://forum.ovoenergy.com/' },
      { text: 'OVO Energy app', url: 'https://www.ovoenergy.com/ovo-app' },
    ],
  },
];

export function Layout({ children, partnership }: Props) {
  return (
    <>
      <StyledIcons>
        <Header items={headerItems} />
        <StyledAppContainer>{children}</StyledAppContainer>
        {partnership === 'mini' && <MiniRegisterInterest />}
        <Footer />
        {partnership === 'mini' && <MiniTerms />}
        {partnership === 'vanarama' && <LicenseDisclaimer />}
      </StyledIcons>
    </>
  );
}
