import { P, Margin } from '@ovotech/nebula';
import * as React from 'react';
import { Container, StyledFooter } from '../Footer/Footer.styled';

export const LicenseDisclaimer = () => (
  <StyledFooter>
    <Container>
      <Margin left={8}>
        <P>
          OVO Energy Ltd is authorised and regulated by the Financial Conduct
          Authority under firm reference number 811744 as a credit broker not a
          lender. OVO Energy Ltd is registered in England (No. 06890795) at 1
          Rivergate, Temple Quays, Bristol, BS1 6ED.
        </P>
      </Margin>
    </Container>
  </StyledFooter>
);
