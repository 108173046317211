import React from 'react';
import OVOLogo from '../../images/ovo.svg';
import MitsubishiLogo from '../../images/mitsubishi.png';
import { Image, MitsubishiImage } from './HeaderLogo.styled';

export const MitsubishiHeader = () => (
  <header>
    <MitsubishiImage src={MitsubishiLogo} />
    <Image src={OVOLogo} />
  </header>
);
