import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 10rem;
  padding-bottom: 3rem;
  clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
  background-image: url("data:image/svg+xml,%3Csvg width='172' height='491' viewBox='0 0 172 491' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-268.246 348.797L171.552 283.683L8.0433 0.477448L-268.246 348.797Z' fill='white'/%3E%3C/svg%3E"),
    url("data:image/svg+xml,%3Csvg width='380' height='755' viewBox='0 0 380 755' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M425.5 143.478C256.724 143.478 120 280.201 120 448.978C120 617.754 256.724 754.478 425.5 754.478C594.276 754.478 731 617.754 731 448.978C731 280.201 594.276 143.478 425.5 143.478ZM425.5 574.182C356.387 574.182 300.295 518.091 300.295 448.978C300.295 379.864 356.387 323.773 425.5 323.773C494.613 323.773 550.705 379.864 550.705 448.978C550.705 518.091 494.613 574.182 425.5 574.182Z' fill='white'/%3E%3Ccircle cx='96' cy='96.4775' r='96' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, no-repeat;
  background-size: 7%, 13%;
  background-position: left top 30%, right bottom;
  background-color: #f9f9f9;
  width: 100vw;
`;

export const Content = styled.div`
  ${({ theme: { card, mediaQueries } }) => `
  ${mediaQueries.mediumAndUp} {
      flex: 0 0 50%;
      padding: ${card.padding[1]};
    };
      flex: 0 0 50%;
      padding: 0;
  `}
`;

export const Image = styled.img`
  ${({ theme: { mediaQueries } }) => `
    flex: 0 0 50%;
    max-width: 100%;
    object-fit: contain;
    ${mediaQueries.mediumAndUp} {
     flex: 0 0 50%;
     max-width: 50%;
     object-fit: contain;
    };
  `}
`;

export const FormContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: -1.6re;
  overflow-x: hidden;

  @media (min-width: 576px) {
    display: flex;
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin: 0 auto;
    width: 540px;
    max-width: 100%;
    margin-bottom: -1.6re;
    overflow-x: hidden;
  }

  @media (min-width: 768px) {
    display: flex;
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin: 0 auto;
    width: 800px;
    max-width: 100%;
    margin-bottom: -1.6re;
    overflow-x: hidden;
  }

  @media (min-width: 992px) {
    display: flex;
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin: 0 auto;
    width: 960px;
    max-width: 100%;
    margin-bottom: -1.6re;
    overflow-x: hidden;
  }

  @media (min-width: 1200px) {
    display: flex;
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin: 0 auto;
    width: 1140px;
    max-width: 100%;
    margin-bottom: -1.6re;
    overflow-x: hidden;
  }
`;
