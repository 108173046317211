import React, { useState } from 'react';
import {
  HeaderDiv,
  MobileHeaderDiv,
  LogoWrapperDiv,
  StyledUL,
  DesktopHeader,
  MobileHeader,
  StyledMenuButton,
  StyledAOVOLogo,
  StyledLoginA,
} from './Header.styled';

import profileIcon from '../../images/profileIcon.svg';

import { HeaderLink } from './HeaderLink';
import { HeaderMenu } from './HeaderMenu';
import { SideMenu } from './Mobile/SideMenu';
import OVOLogoIcon from '../../images/ovoLogo.svg';
import HamburgerMenu from '../../images/hamburgerMenu.svg';

export type HeaderMenuItem = {
  text: string;
  items: Array<HeaderItem>;
};

export type HeaderItem = {
  text: string;
  url: string;
};

type headerProps = {
  items: Array<HeaderItem | HeaderMenuItem>;
};

const OVOLogo = () => {
  return (
    <LogoWrapperDiv>
      <img src={OVOLogoIcon} />
    </LogoWrapperDiv>
  );
};

export function isHeaderItem(
  item: HeaderItem | HeaderMenuItem,
): item is HeaderItem {
  return (item as HeaderItem).url !== undefined;
}

export const Header = (props: headerProps) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const flipSideMenu = (status: boolean): void => {
    setSideMenuOpen(status);
  };

  const { items } = props;

  return (
    <>
      <DesktopHeader>
        <HeaderDiv>
          <StyledAOVOLogo href="https://www.ovoenergy.com/" title="OVO">
            <OVOLogo />
          </StyledAOVOLogo>

          <StyledUL>
            {items.map(item => {
              if (isHeaderItem(item)) {
                return (
                  <HeaderLink text={item.text} url={item.url} key={item.text} />
                );
              } else {
                return (
                  <HeaderMenu
                    key={item.text}
                    text={item.text}
                    items={item.items}
                  />
                );
              }
            })}
          </StyledUL>
          <StyledLoginA href="https://my.ovoenergy.com/login">
            <div>
              <img src={profileIcon} />
            </div>
            My Account
          </StyledLoginA>
        </HeaderDiv>
      </DesktopHeader>
      <MobileHeader>
        <MobileHeaderDiv>
          <a href="https://www.ovoenergy.com/" title="OVO">
            <OVOLogo />
          </a>
          <SideMenu
            isOpen={sideMenuOpen}
            flipSideMenu={flipSideMenu}
            items={items}
          />
          <div>
            <StyledMenuButton onClick={() => setSideMenuOpen(true)}>
              MENU
              <img src={HamburgerMenu} />
            </StyledMenuButton>
          </div>
        </MobileHeaderDiv>
      </MobileHeader>
    </>
  );
};
