import { Strong, Ol, Li, P, Small, TextLink } from '@ovotech/nebula';
import * as React from 'react';
import { Container, StyledFooter } from '../Footer/Footer.styled';

export const MiniTerms = () => (
  <StyledFooter>
    <Container>
      <div>
        <div>
          <Strong>The important bits</Strong>
          <Ol>
            <Li>
              <P>
                <Small>
                  The offer is for ‘5,000 Free Green Miles’, for customers who
                  sign up to the EV Everywhere tariff and who are on Economy 7.
                  This will be delivered via a £11 credit being applied to your
                  OVO Energy account each month for the first 12 months of your
                  contract, up to a maximum total credit of £132. £132 credit
                  equates to 5,000 free miles of electric driving for customers
                  charging off peak on the Economy 7 EV Everywhere tariff. For
                  customers who do not sign up to the Economy 7 tariff, the
                  offer is equivalent to 3,300 Free Miles. The £132 (£11 per
                  month for 12 months) is a fixed amount and will not be
                  adjusted over the course of the offer period. A 2020 MINI
                  Electric has an efficiency of 4.0 miles/kWh as per WLTP
                  testing. Find out more about WLTP{' '}
                  <TextLink
                    href="https://www.vehicle-certification-agency.gov.uk/fcb/wltp.asp"
                    opensInNewWindow
                  >
                    here
                  </TextLink>
                  . 5,000 miles equates to 1250kWh of energy. £132 can purchase
                  1250kWh of energy using an assumed electricity unit rate of
                  10.51p/kWh, available at off-peak times on OVO’s 2 Year Fixed
                  Economy 7 tariff (the tariff component of the ‘EV Everywhere’
                  bundle) in the North Eastern England region (chosen as prices
                  in this region tend to be close to the average for the UK).
                </Small>
              </P>
            </Li>
            <Li>
              <Small>
                Free subscription for 2 years to the UK&rsquo;s largest public
                charging network, worth £188: By signing up to the EV Everywhere
                bundle for 2 years, you will get a free subscription to the bp
                pulse network (normal cost £7.85 a month). This subscription
                will give you access to over 7,000 charge points across the UK,
                with charging tariffs starting at just 12p perkWh.&nbsp;
              </Small>
            </Li>
            <Li>
              <Small>
                Free 100% renewable electricity for 2 years included, worth
                £120: By signing up to EV Everywhere for 2 years, you’ll get our
                Green Electricity&nbsp;add-on with your fixed plan for free
                (normal cost £120 for 2 years). If you&rsquo;re signed up to
                Green Electricity, we purchase renewable certificates for 100%
                of your electricity use from green sources such as wind, solar,
                geothermal, hydro, and waste-to-energy. Visit{' '}
                <TextLink
                  href="https://www.ovoenergy.com/fuel-mix"
                  opensInNewWindow
                >
                  www.ovoenergy.com/fuel-mix
                </TextLink>{' '}
                for more information.&nbsp;
              </Small>
            </Li>
            <Li>
              <Small>
                We&rsquo;ll verify the eligibility of your VIN with MINI as part
                of the sign up process. This may take up to 10 working days -
                we&rsquo;ll contact you directly if any issues come up.
              </Small>
            </Li>
          </Ol>
          <P>
            <TextLink
              href="https://www.ovoenergy.com/ev-everywhere/terms"
              opensInNewWindow
            >
              EV Everywhere Terms and Conditions
            </TextLink>
          </P>
          <P>
            <TextLink
              href="https://www.ovoenergy.com/terms/mini-free-green-miles"
              opensInNewWindow
            >
              MINI Free Green Miles Terms and Conditions
            </TextLink>
          </P>
        </div>
      </div>
    </Container>
  </StyledFooter>
);
