import React, { useState } from 'react';
import { HeaderMenuItem } from '../Header';
import {
  StyledSpan,
  StyledCaretImage,
  StyledDUL,
  StyledLi,
} from './SideMenu.styled';
import { SideMenuLink } from './SideMenuLink';

export const SideMenuList = (props: HeaderMenuItem) => {
  const { text, items } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <StyledLi>
      <StyledSpan onClick={() => setMenuOpen(!menuOpen)}>
        {text}
        <StyledCaretImage></StyledCaretImage>
      </StyledSpan>
      <StyledDUL menuOpen={menuOpen}>
        {items.map(item => {
          return (
            <SideMenuLink
              key={item.text}
              text={item.text}
              url={item.url}
            ></SideMenuLink>
          );
        })}
      </StyledDUL>
    </StyledLi>
  );
};
