import { Ul, Ol, Heading2, Heading3, Heading1, Small } from '@ovotech/nebula';
import styled from 'styled-components';
import React from 'react';

export const Container = styled.div`
  width: 100%;
  @media (min-width: 1200px) {
    padding-top: 80px;
  }
`;

export const StyledDiv = styled.div`
  overflow: hidden;
`;

export const Image = styled.img`
  ${({ theme: { mediaQueries } }) => `
    position: relative;
    width: 100%;
    ${mediaQueries.mediumAndUp} {
     right: 0;
     width: 50%;
     position: absolute;
    };

    @media (min-width: 1400px) {
     right: 0;
     width: 40%;
     position: absolute;
    };
  `}
`;

export const TextContainer = styled.div`
  ${({ theme: { mediaQueries, space } }) => `
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: ${space[4]};
  padding-bottom: ${space[6]};
  padding-right: ${space[4]};

  ${mediaQueries.mediumAndUp} {
    width: 50%;
    padding-top: ${space[4]};
    padding-bottom: ${space[10]};
    padding-right: ${space[10]};
  };
  `}
`;

export const StyledUl = styled(props => <Ul {...props} />)`
  padding-left: 1rem;
  list-style-position: outside;
`;

export const StyledOl = styled(props => <Ol {...props} />)`
  list-style-position: outside;
  padding-left: 1rem;
`;

export const StyledHeading1 = styled.h1`
  ${({ theme: { mediaQueries } }) => `
   max-width: 1000%;

  ${mediaQueries.largeAndUp} {
    max-width: 40%;
  };
  `}
`;

export const StyledHeading2Green = styled(props => <Heading2 {...props} />)`
  color: #0d8426;
  ${({ theme: { mediaQueries } }) => `
   max-width: 1000%;
  ${mediaQueries.largeAndUp} {
    max-width: 100%;
  };
  `}
`;

export const StyledHeading3 = styled(props => <Heading3 {...props} />)`
  color: #555c6b;
  ${({ theme: { mediaQueries } }) => `
   max-width: 1000%;
  ${mediaQueries.largeAndUp} {
    max-width: 100%;
  };
  `}
`;

export const StyledHeading4 = styled.h2`
  ${({ theme: { mediaQueries } }) => `
   max-width: 100%;
  color: #555c6b;

  ${mediaQueries.largeAndUp} {
    max-width: 60%;
  color: #555c6b;
  };
  `}
`;

export const StyledText = styled.p`
  color: #555c6b;
  ${({ theme: { mediaQueries } }) => `
  max-width: 100%;
  font-size: 14px;
  ${mediaQueries.smallAndUp} {
    font-size: unset;
  };
 ${mediaQueries.largeAndUp} {
   max-width: 100%;
 };
 `}
`;

export const StyledHeader = styled(props => <Heading1 {...props} />)`
  ${({ theme: { mediaQueries } }) => `
  font-size: 32px;
  line-height: 32px;
 ${mediaQueries.largeAndUp} {
  font-size: 48px;
  line-height: 48px;
 };
 `}
`;

export const StyledSmall = styled(props => <Small {...props} />)`
  font-weight: lighter;
  font-style: italic;
`;
