import * as mixpanel from 'mixpanel-browser';
import { MixpanelEvents, EventData } from './AnalyticsEvents';

export const getMixpanelToken = (): string | null => {
  const environment = process.env.GATSBY_ENVIRONMENT;
  const mixpanelUatToken = process.env.GATSBY_MIXPANEL_UAT_TOKEN;
  const mixpanelProdToken = process.env.GATSBY_MIXPANEL_PROD_TOKEN;

  if (!environment || !mixpanelUatToken || !mixpanelProdToken) {
    return null;
  }

  if (environment.toLowerCase() === 'prod') {
    return mixpanelProdToken;
  } else if (environment.toLowerCase() === 'uat') {
    return mixpanelUatToken;
  } else {
    return null;
  }
};

export class Mixpanel {
  public static track(eventName: MixpanelEvents, eventdata?: EventData) {
    const token = getMixpanelToken();
    if (!token) {
      return;
    }

    mixpanel.init(token);
    mixpanel.track(eventName, eventdata ? eventdata : {});
  }
}
