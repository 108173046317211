import React, { useRef, useState } from 'react';
import {
  StyledLI,
  StyledSpan,
  FlexRowDiv,
  StyledMenuUL,
} from './Header.styled';

import { StyledCaretImage, StyledLi } from './Mobile/SideMenu.styled';

import { HeaderMenuItem } from './Header';
import { useClickOutside } from './utils/useClickOutside';

export const HeaderMenu = (props: HeaderMenuItem) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLLIElement>(null);
  const { text, items } = props;
  const clickHandler = () => {
    setIsOpen(false);
  };
  useClickOutside(menuRef, clickHandler);

  return (
    <StyledLI key={text} ref={menuRef}>
      <FlexRowDiv
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <StyledSpan
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {text}
          <StyledCaretImage></StyledCaretImage>
        </StyledSpan>
      </FlexRowDiv>
      {isOpen && (
        <StyledMenuUL>
          {items.map(item => {
            return (
              <StyledLi key={item.text}>
                <a href={item.url}>{item.text}</a>
              </StyledLi>
            );
          })}
        </StyledMenuUL>
      )}
    </StyledLI>
  );
};
