import styled from 'styled-components';
import { Ul, Li } from '@ovotech/nebula';
import React from 'react';

interface StyledULProps {
  menuOpen: boolean;
}

interface StyledSideMenuDivProps {
  isOpen: boolean;
}

export const StyledSideMenuDiv = styled.div<StyledSideMenuDivProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 85%;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: rgb(47, 55, 73);
  overflow: hidden scroll;
  transition: all 0.3s ease-in-out 0s;
  z-index: 5;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  padding: 15px 30px;
  transform: translateX(100%);
  box-shadow: rgb(2 25 39 / 55%) -6px 0px 70px;
  transform: ${props =>
    props.isOpen ? 'translateX(0px)' : 'translateX(100%)'};
`;

export const StyledCloseIcon = styled.span`
  color: white;
  margin-bottom: 15px;
  line-height: 40px;
  text-decoration: none;
  align-self: flex-end;
  cursor: pointer;
  font-size: 65px;
  font-weight: 500;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
`;

export const ItemContainerDiv = styled.div`
  display: flex;
  margin: 15px 0px;
  justify-content: center;
`;

export const StyledSideUL = styled(props => <Ul {...props} />)`
  list-style: none;
  margin: 15px -30px;
  display: flex;
  font-size: 1.8rem;
  flex-direction: column;
`;

export const StyledSideA = styled.a`
  font-size: 1.8rem;
  display: block;
  padding: 0px 30px;
  text-decoration: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-weight: 600;
  line-height: 78px;
  transition: all 0.4s ease-out 0s;
  font-family: Montserrat;
  &:hover {
    background: rgb(28, 36, 53);
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
`;

export const StyledSpan = styled.span`
  display: block;
  padding: 0px 30px;
  text-decoration: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-weight: 600;
  line-height: 78px;
  transition: all 0.4s ease-out 0s;
  font-family: Montserrat;
  &:hover {
    background: rgb(28, 36, 53);
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
`;

export const StyledCaretImage = styled.i`
  cursor: pointer;
  content: '';
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-left: 4px;
  vertical-align: middle;
  border-width: 4px 4px 0px;
  border-style: solid;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-image: initial;
  border-top-color: inherit;
`;

export const StyledDUL = styled(props => <Ul {...props} />)<StyledULProps>`
  margin: 0;
  background-color: rgb(28, 36, 53);
  transition: all 0.4s ease-out 0s;
  overflow-y: hidden;
  list-style: none;
  max-height: ${props => (props.menuOpen ? '1000px' : '0px')};
  li {
    padding-left: 30px;
  }
`;

export const StyledLi = styled(props => <Li {...props} />)`
  font-size: 1.8rem;
  margin: 0;
`;

export const StyledLoginA = styled.a`
  text-decoration: none;
  background: rgb(10, 152, 40);
  color: rgb(255, 255, 255);
  line-height: 40px;
  border-radius: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 14px;
  align-self: center;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  div {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    width: 30px;
    height: 30px;
    line-height: 40px;
    white-space: nowrap;
    svg {
      margin-right: 8px;
    }
  }
`;
