import * as React from 'react';
import HeroImage from '../../../images/mitsubishiAngled.png';
import { Heading1, Heading4, TextLink } from '@ovotech/nebula';
import { Container, Image, StyledDiv, TextContainer } from './Banner.styled';
import { MitsubishiHeader } from '../../Header/MitsubishiHeader';

export const MitsubishiBanner = () => (
  <>
    <Container>
      <MitsubishiHeader />
      <StyledDiv>
        <Image src={HeroImage} alt="hero image" />
        <TextContainer>
          <Heading1>10,000 free green miles</Heading1>
          <Heading4>Unfortunately this offer has now ended.</Heading4>
          <Heading4>
            Why not visit{' '}
            <TextLink href="https://www.ovoenergy.com/">OVO Energy</TextLink> to
            see more great products and start your journey to zero carbon
            living.
          </Heading4>
        </TextContainer>
      </StyledDiv>
    </Container>
  </>
);
