import styled from 'styled-components';
import { Ul, Li } from '@ovotech/nebula';
import React from 'react';

export const HeaderDiv = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const MobileHeaderDiv = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const LogoWrapperDiv = styled.div`
  height: 50px;
  width: 130px;
  color: rgb(10, 152, 40);
`;

export const StyledLI = styled(props => <Li {...props} />)`
  display: inline-block;
  position: relative;
  height: 1em;
  margin: 0 20px 0 0;
  font-size: 1.6rem;
  line-height 1.4rem;
  font-family: "Open Sans", sans-serif;
    cursor: pointer;
`;

export const StyledUL = styled(props => <Ul {...props} />)`
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 1.6rem;
  &::before {
    content: "";
    border-left-width: 2px;
    border-left-style: solid;
    border-color: #0B9828;
    display: block;
    height: 1em;
    margin-right: 20px;
}
  }
`;

export const StyledMenuUL = styled(props => <Ul {...props} />)`
  z-index: 1;
  list-style: none;
  display: inline-block;
  position: absolute;
  min-width: 160px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-left: 0;
  margin-top: 0;
  li {
    box-sizing: border-box;
    position: relative;
    font-size: 1.6rem;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    &:hover {
      background-color: rgb(245, 245, 245);
    }
    a {
      display: block;
      white-space: nowrap;
      box-sizing: border-box;
      color: rgb(10, 152, 40);
      font-weight: 600;
      font-family: Montserrat;
      text-decoration: none;
      padding: 10px 20px;
      &:hover {
        color: rgb(30, 105, 55);
        outline: 0px;
      }
    }
  }
`;

export const StyledA = styled.a`
  color: rgb(10, 152, 40);
  font-weight: 600;
  font-family: Montserrat;
  text-decoration: none;
  line-height: 1.8rem;
`;

export const StyledSpan = styled.span`
  color: rgb(10, 152, 40);
  font-weight: 600;
  font-family: Montserrat;
  line-height: 1.8rem;
`;

export const FlexRowDiv = styled.div`
  display: flex;
`;

export const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAOVOLogo = styled.a`
  margin-right: 20px;
  margin-top: -8px;
  position: relative;
  height: 100%;
  display: block;
`;

export const DesktopHeader = styled.header`
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 3;
  @media (max-width: 1199px) {
    display: none;
  }
`;

export const MobileHeader = styled.header`
  width: 100%;
  background-color: white;
  z-index: 3;
  @media (min-width: 1200px) {
    display: none;
  }
`;

export const StyledMenuButton = styled.button`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: rgb(10, 152, 40);
  background: none;
  display: flex;
  border: none;
  -webkit-box-align: center;
  align-items: center;
  line-height: 22px;
  font-size: 20px;
  &:hover {
    cursor: pointer;
  }
  img {
    height: 15px;
    width: 20px;
    cursor: pointer;
    fill: rgb(10, 152, 40);
    margin-left: 10px;
  }
`;

export const StyledLoginA = styled.a`
  text-decoration: none;
  background: rgb(10, 152, 40);
  color: rgb(255, 255, 255);
  line-height: 40px;
  border-radius: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 14px;
  align-self: center;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  div {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    width: 30px;
    height: 30px;
    line-height: 40px;
    white-space: nowrap;
    svg {
      margin-right: 8px;
    }
  }
`;
