export enum GoogleTagManagerEvents {
  GetEVMitsubishiProductPage = 'GetEVMitsubishiProductPage',
  GetEVNonexistentPartnershipPage = 'GetEVNonexistentPartnershipPage',
  ClickedCTAButtonInEVMitsubishiProductPage = 'ClickedCTAButtonInEVMitsubishiProductPage',
  GetEVMiniProductPage = 'GetEVMiniProductPage',
  ClickedCTAButtonInEVMiniProductPage = 'ClickedCTAButtonInEVMiniProductPage',
  GetEVMitsubishiTerminationProductPage = 'GetEVMitsubishiTerminationProductPage',
  GetEVMiniTerminationProductPage = 'GetEVMiniTerminationProductPage',
  GetEVLeasingProductPage = 'GetEVLeasingProductPage',
  FakeDoorMainPage = 'Fake Door Leasing Landing Page - FakeDoorProductPage',
  ViewedEVLeasingCurrentVehiclePage = 'ViewedEVLeasingCurrentVehiclePage',
  ClickedNextEVLeasingCurrentVehiclePage = 'ClickedNextEVLeasingCurrentVehiclePage',
  ViewedEVLeasingElectricVehiclePage = 'ViewedEVLeasingElectricVehiclePage',
  ClickedNextEVLeasingElectricVehiclePage = 'ClickedNextEVLeasingElectricVehiclePage',
  ViewedEVLeasingComparisonPage = 'ViewedEVLeasingComparisonPage',
  ClickedVanaramaLinkInBannerEVLeasingPage = 'ClickedVanaramaLinkInBannerEVLeasingPage',
  ClickedVanaramaLinkInLeasingStepsEVLeasingPage = 'ClickedVanaramaLinkInLeasingStepsEVLeasingPage',
  ClickedVanaramaLinkInComparisonEVLeasingPage = 'ClickedVanaramaLinkInComparisonEVLeasingPage',
  ClickedGetAQuoteEVLeasingPage = 'ClickedGetAQuoteEVLeasingPage',
}

export enum MixpanelEvents {
  GetEVMitsubishiProductPage = 'Partnership Landing Page - GetEVMitsubishiProductPage',
  GetEVNonexistentPartnershipPage = 'Partnership Landing Page - GetEVNonexistentPartnershipPage',
  ClickedCTAButtonInEVMitsubishiProductPage = 'Partnership Landing Page - ClickedCTAButtonInEVMitsubishiProductPage',
  GetEVMiniProductPage = 'Partnership Landing Page - GetEVMiniProductPage',
  ClickedCTAButtonInEVMiniProductPage = 'Partnership Landing Page - ClickedCTAButtonInEVMiniProductPage',
  GetEVMitsubishiTerminationProductPage = 'Partnership Termincation Landing Page - GetEVMitsubishiTerminationProductPage',
  GetEVMiniTerminationProductPage = 'Partnership Termination Landing Page - GetEVMiniTerminationProductPage',
  GetEVLeasingProductPage = 'Leasing Landing Page - GetEVLeasingProductPage',
  ViewedEVLeasingCurrentVehiclePage = 'Leasing Landing Page - ViewedEVLeasingCurrentVehiclePage',
  ClickedNextEVLeasingCurrentVehiclePage = 'Leasing Landing Page - ClickedNextEVLeasingCurrentVehiclePage',
  ViewedEVLeasingElectricVehiclePage = 'Leasing Landing Page - ViewedEVLeasingElectricVehiclePage',
  ClickedNextEVLeasingElectricVehiclePage = 'Leasing Landing Page - ClickedNextEVLeasingElectricVehiclePage',
  ViewedEVLeasingComparisonPage = 'Leasing Landing Page - ViewedEVLeasingComparisonPage',
  ClickedVanaramaLinkInBannerEVLeasingPage = 'Leasing Landing Page - ClickedVanaramaLinkInBannerEVLeasingPage',
  ClickedVanaramaLinkInLeasingStepsEVLeasingPage = 'Leasing Landing Page - ClickedVanaramaLinkInLeasingStepsEVLeasingPage',
  ClickedVanaramaLinkInComparisonEVLeasingPage = 'Leasing Landing Page - ClickedVanaramaLinkInComparisonEVLeasingPage',
  ClickedGetAQuoteEVLeasingPage = 'Leasing Landing Page - ClickedGetAQuoteEVLeasingPage',
  FakeDoorExperimentModal = 'Fake Door Experiment Modal',
  LeasingPageModal = 'Fake Door Leasing Page Experiment Modal',
  FakeDoorViewOffer = 'Fake Door Leasing Page Clicked View Offer',
  FakeDoorLoadMore = 'Fake Door Leasing Page Clicked Load More',
  ModalClose = 'Fake Door Closed Modal',
  FakeDoorMainPage = 'Fake Door Leasing Landing Page - FakeDoorProductPage',
}

export interface EventData {
  [key: string]: string | undefined;
}
