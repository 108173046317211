import * as React from 'react';
import axios from 'axios';
import { Mixpanel } from '../../analytics/Mixpanel';
import { GoogleTagManager } from '../../analytics/GoogleTagManager';
import {
  GoogleTagManagerEvents,
  MixpanelEvents,
} from '../../analytics/AnalyticsEvents';
import {
  Stack,
  TextField,
  EmailField,
  CTAButton,
  Checkbox,
  getErrorMessage,
  Heading2,
  Margin,
  TextLink,
  P,
  WarningNotification,
  SuccessNotification,
} from '@ovotech/nebula';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Container, FormContainer } from './MiniRegisterInterest.styled';
import * as Sentry from '@sentry/gatsby';

type ErrorSummaryList = Array<{
  id: string;
  message: string;
}>;

const isValidEmailRegex =
  /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
export function MiniRegisterInterest() {
  const [successState, setSuccessState] = React.useState<boolean | null>(null);
  const [errors, setErrors] = React.useState<ErrorSummaryList>([]);
  const [vin, setVin] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [purchasedMini, setPurchasedMini] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const nonProd =
    (typeof window !== 'undefined' &&
      window.location.hostname.indexOf('uat') > -1) ||
    (typeof window !== 'undefined' &&
      window.location.hostname.indexOf('localhost') > -1);
  const apiURL = nonProd
    ? 'https://vps.evs-uat.ovotech.org.uk/vps/v1/email'
    : 'https://vps.evs.ovotech.org.uk/vps/v1/email';
  const partnerKey = process.env.GATSBY_MINI_PARTNER_KEY;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newErrors: ErrorSummaryList = [];

    if (!vin) {
      newErrors.push({ id: 'vin', message: 'This field is required.' });
    }
    if (!email) {
      newErrors.push({ id: 'email', message: 'This field is required.' });
    } else if (!isValidEmailRegex.test(email)) {
      newErrors.push({ id: 'email', message: 'This email is not valid.' });
    }
    if (!purchasedMini) {
      newErrors.push({
        id: 'purchasedMini',
        message: 'This field is required.',
      });
    }

    if (newErrors.length === 0) {
      setErrors([]);
      Mixpanel.track(MixpanelEvents.ClickedCTAButtonInEVMiniProductPage);
      GoogleTagManager.track(
        GoogleTagManagerEvents.ClickedCTAButtonInEVMiniProductPage,
      );
      try {
        setLoading(true);
        const { status } = await axios({
          method: 'patch',
          url: apiURL,
          data: {
            vin,
            email,
            partnerKey,
          },
        });
        if (status === 200) {
          setLoading(false);
          setVin('');
          setEmail('');
          setPurchasedMini(false);
          setSuccessState(true);
        } else {
          setLoading(false);
          setSuccessState(false);
        }
      } catch (error) {
        setLoading(false);
        setSuccessState(false);
        Sentry.captureException(error);
        Sentry.captureMessage(
          'Customer could not register their mini interest',
        );
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Container className="interest-form">
      <FormContainer id="register-interest-form">
        <div>
          <Heading2>Register Interest</Heading2>
          <P>
            Already an OVO member? Email your VIN directly to{' '}
            <TextLink href="mailto:electricvehicles@ovoenergy.com">
              electricvehicles@ovoenergy.com
            </TextLink>
          </P>
          <Margin bottom={6}>
            <P>
              Enter your details below and we&rsquo;ll be in touch to get you
              started
            </P>
          </Margin>
        </div>
        <div className="register-interest">
          <div className="w-4/5 md:w-5/12">
            <form noValidate={true} onSubmit={onSubmit}>
              <Stack spaceBetween={4}>
                <EmailField
                  id="email"
                  label="Email"
                  error={getErrorMessage('email', errors)}
                  value={email}
                  onChange={(event: {
                    target: { value: React.SetStateAction<string> };
                  }) => setEmail(event.target.value)}
                  data-testid="email-field"
                />
                <TextField
                  id="vin"
                  label="Last 7 characters of your VIN&#x2074;:"
                  error={getErrorMessage('vin', errors)}
                  value={vin}
                  onChange={(event: {
                    target: { value: React.SetStateAction<string> };
                  }) => setVin(event.target.value)}
                  maxLength={7}
                  data-testid="vin-field"
                />
                <P className="text-sm font-normal italic">
                  Your VIN can be found in the bottom left corner of your
                  windscreen
                </P>
                <P>
                  If you have not taken ownership of your MINI Electric yet
                  please contact your dealer who can supply your VIN
                </P>
                <Checkbox
                  id="purchasedMini"
                  label="I confirm that I have purchased a new MINI Electric"
                  error={getErrorMessage('purchasedMini', errors)}
                  checked={purchasedMini}
                  onChange={(event: {
                    target: { checked: React.SetStateAction<boolean> };
                  }) => setPurchasedMini(event.target.checked)}
                  data-testid="purchasedMiniCheckbox"
                />
                {successState && (
                  <SuccessNotification title="Success" id="success">
                    <P>
                      You have successfully registered your interest, check your
                      inbox
                    </P>
                  </SuccessNotification>
                )}
                {successState === false && (
                  <WarningNotification title="Warning" id="not-eligible">
                    <P>VIN and/or email is not eligible for the offer</P>
                  </WarningNotification>
                )}
                {errors.length > 0 && (
                  <WarningNotification title="Warning" id="error">
                    <P>There seems to be a problem with your input</P>
                  </WarningNotification>
                )}
                <Margin bottom={2}>
                  <CTAButton
                    variant="missionPrimary"
                    data-testid="submit-button"
                    type="submit"
                  >
                    {loading ? <LoadingSpinner /> : 'Get started'}
                  </CTAButton>
                </Margin>
              </Stack>
            </form>
          </div>
        </div>
      </FormContainer>
    </Container>
  );
}
