import TagManager from 'react-gtm-module';
import { GoogleTagManagerEvents, EventData } from './AnalyticsEvents';

export const generateDataLayerObject = (
  eventName: GoogleTagManagerEvents | undefined,
  eventData?: EventData | undefined,
): EventData => {
  const event = { event: eventName };
  const eventPayload = eventData ? eventData : {};

  return { ...event, ...eventPayload };
};

export class GoogleTagManager {
  public static track(
    eventName: GoogleTagManagerEvents,
    eventData?: EventData,
  ) {
    const gtmId = process.env.GATSBY_GOOGLE_TAG_MANAGER_ID;
    const auth = process.env.GATSBY_GOOGLE_TAG_MANAGER_AUTH;
    const preview = process.env.GATSBY_GOOGLE_TAG_MANAGER_PREVIEW;

    if (gtmId && auth && preview) {
      const tagManagerArgs = {
        gtmId,
        auth,
        preview,
        dataLayer: generateDataLayerObject(eventName, eventData),
      };

      TagManager.initialize(tagManagerArgs);
    }
  }
}
