import styled from 'styled-components';

export const StyledAppContainer = styled.main`
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: -1.6re;
  overflow-x: hidden;

  @media (min-width: 576px) {
    display: flex;
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin: 0 auto;
    width: 540px;
    max-width: 100%;
    margin-bottom: -1.6re;
    overflow-x: hidden;
  }

  @media (min-width: 768px) {
    display: flex;
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin: 0 auto;
    width: 800px;
    max-width: 100%;
    margin-bottom: -1.6re;
    overflow-x: hidden;
  }

  @media (min-width: 992px) {
    display: flex;
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin: 0 auto;
    width: 960px;
    max-width: 100%;
    margin-bottom: -1.6re;
    overflow-x: hidden;
  }

  @media (min-width: 1200px) {
    display: flex;
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin: 0 auto;
    width: 1140px;
    max-width: 100%;
    margin-bottom: -1.6re;
    overflow-x: hidden;
  }
`;

export const StyledIcons = styled.div`
  ${({ theme: { mediaQueries } }) => `
  ${mediaQueries.mediumAndUp} {
    background-image: url("data:image/svg+xml,%3Csvg width='164' height='184' viewBox='0 0 164 184' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-113.001 178.874L163.23 183.618L30.6468 0.372018L-113.001 178.874Z' fill='%23B6D727'/%3E%3C/svg%3E%0A"),
      url("data:image/svg+xml,%3Csvg width='163' height='375' viewBox='0 0 163 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-24.5 0C-128.086 0 -212 83.9139 -212 187.5C-212 291.086 -128.086 375 -24.5 375C79.0861 375 163 291.086 163 187.5C163 83.9139 79.0861 0 -24.5 0ZM-24.5 264.344C-66.918 264.344 -101.344 229.918 -101.344 187.5C-101.344 145.082 -66.918 110.656 -24.5 110.656C17.918 110.656 52.3443 145.082 52.3443 187.5C52.3443 229.918 17.918 264.344 -24.5 264.344Z' fill='%2375B810'/%3E%3C/svg%3E "),
      url("data:image/svg+xml,%3Csvg width='136' height='184' viewBox='0 0 136 184' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.1154e-05 178.874L276.231 183.618L143.648 0.372018L1.1154e-05 178.874Z' fill='%23B6D727'/%3E%3C/svg%3E ");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 6%, 6%, 6%;
    background-position: left 9%, left 33%, right 40%;
    };
      background-image: none;
  `}
`;
