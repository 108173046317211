import '@ovotech/ovoenergy-theme/dist/global.css';
import { Layout } from '../components/Layout';
import * as React from 'react';
import { Mixpanel } from '../analytics/Mixpanel';
import { GoogleTagManager } from '../analytics/GoogleTagManager';
import {
  GoogleTagManagerEvents,
  MixpanelEvents,
} from '../analytics/AnalyticsEvents';
import { MitsubishiBanner } from '../components/HomePageSections/Banner/MitsubishiBanner';
import { ThemeProvider } from 'styled-components';
import { createTheme } from '@ovotech/nebula';
import { AdditionalConfig, themeConfig } from '@ovotech/ovoenergy-theme';
import { Seo } from '../components/Seo';
import { StyledDiv } from '../components/Mitsubishi.styled';

const theme = createTheme<AdditionalConfig>(themeConfig);

const MitsubishiOutlanderTerminationPage = () => {
  React.useEffect(() => {
    Mixpanel.track(MixpanelEvents.GetEVMitsubishiTerminationProductPage);
    GoogleTagManager.track(
      GoogleTagManagerEvents.GetEVMitsubishiTerminationProductPage,
    );
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Layout partnership="mitsubishi">
        <Seo
          title="Mitsubishi Offer - Ended"
          description="Mitsubishi OVO Offer - Ended"
        />
        <StyledDiv>
          <MitsubishiBanner />
        </StyledDiv>
      </Layout>
    </ThemeProvider>
  );
};

export default MitsubishiOutlanderTerminationPage;
